@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Animated Background */
.animated-dots {
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  animation: move-dots 5s linear infinite;
}

@keyframes move-dots {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.login-container h1 {
  margin-bottom: 1rem;
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container input {
  margin: 0.5rem 0;
  padding: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.login-container button {
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-container button:disabled {
  background-color: #aaa;
}

.error {
  color: red;
  margin-bottom: 1rem;
}



/* Create the animated dots */
.animated-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}


/* Animation to move the dots */
@keyframes moveDots {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100vw, 100vh);
  }
}



